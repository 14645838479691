import { PairedEntityGenqlSelection, MerchantGenqlSelection } from '../../__generated-global__';

export const merchantSelection: MerchantGenqlSelection = {
  __typename: true,
  on_CompanyAffiliate: {
    id: true,
    name: true,
  },
  on_Customer: {
    id: true,
    name: true,
  },
  on_Vendor: {
    id: true,
    name: true,
  },
};

export const pairedEntitySelection: PairedEntityGenqlSelection = {
  __typename: true,
  on_Bill: {
    id: true,
    vendor: {
      name: true,
    },
    createdDate: true,
    amount: true,
    currency: true,
  },
  on_Payout: {
    id: true,
    type: true,
    integrationType: true,
    eventTime: true,
    amount: true,
    currency: true,
  },
  on_Topup: {
    id: true,
    type: true,
    integrationType: true,
    eventTime: true,
    amount: true,
    currency: true,
  },
  on_BankTransaction: {
    id: true,
    merchant: merchantSelection,
    description: true,
    postedAt: true,
    amount: true,
    amountInUsd: true,
  },
};

export const accrualFragment = {
  id: true,
};

export const bankTransactionFragment = {
  companyId: true,
  __typename: true,
  id: true,
  classificationVersion: true,
  applyingClassificationJobId: true,
  postedAt: true,
  amountInUsd: true,
  processedByMachineAt: true,
  currency: true,
  description: true,
  source: true,
  sourceSpecificData: true,
  amount: true,
  merchant: merchantSelection,
  attachments: {
    __typename: true,
    url: true,
    name: true,
  },
  userMemo: {
    content: true,
    updatedAt: true,
    updatedBy: true,
  },
  businessEvent: {
    __typename: true,
    classifiedAt: true,
    classifiedBy: true,
    classifierType: true,
    classifications: {
      __typename: true,
      amount: true,
      askTheUserResult: {
        __typename: true,
        data: true,
        type: true,
      },
      businessEvent: true,
      classificationTagId: true,
      classificationTagText: true,
      classificationText: true,
      description: true,
      extraData: true,
      pairedEntityId: true,
      pairedEntityType: true,
      pairingType: true,
      pairedEntity: pairedEntitySelection,
    },
  },
  bankAccount: {
    id: true,
    mask: true,
    connectionStatus: true,
    currency: true,
    displayName: true,
    fullName: true,
    name: true,
    subtype: true,
    institutionName: true,
    type: true,
    source: true,
    vendor: {
      id: true,
      name: true,
      source: true,
      logoUrl: true,
      __typename: true,
    },
  },
};
