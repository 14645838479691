import { MerchantType, SimulateTransactionUpdatedClassification } from '../__generated-global__';
import { gqlClient } from './gqlClient';

function getMerchantType(merchantType?: MerchantType | null) {
  if (!merchantType) return null;

  switch (merchantType) {
    case 'VENDOR':
      return 'Vendor';
    case 'COMPANY_AFFILIATE':
    case 'CUSTOMER':
      return 'CompanyAffiliate';
    default:
      return null;
  }
}

export async function simulateLoanFromTransaction(
  companyId: string,
  transactionId: string,
  classifications?: SimulateTransactionUpdatedClassification[] | null,
  merchantId?: string | null,
  merchantType?: MerchantType | null,
) {
  console.log('in simulateLoanFromTransaction', { classifications });
  const { simulateLoanFromTransaction2 } = await gqlClient.mutation({
    __name: 'simulateLoanFromTransaction2',
    simulateLoanFromTransaction2: {
      __args: {
        input: {
          companyId,
          transactionId,
          classifications: classifications || [],
          merchantId: merchantId || '',
          merchantType: getMerchantType(merchantType),
        },
      },
      on_SimulatedLoanIrrelevantEvent: {
        __typename: true,
        event: true,
      },
      on_SimulatedLoanUpdate: {
        __typename: true,
        createdLoan: {
          isShortTerm: true,
          type: true,
          date: true,
          amount: true,
          lender: {
            name: true,
          },
        },
        deletedLoan: {
          loanId: true,
          isShortTerm: true,
          type: true,
          date: true,
          amount: true,
          lender: {
            name: true,
          },
        },
      },
      on_SimulatedLoanFailure: {
        __typename: true,
        reason: true,
      },
    },
  });

  return simulateLoanFromTransaction2;
}
