import { memo, ReactElement, useEffect, useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import { BankTransactionClassifierType, BusinessMeaning, EnrichedBankTransaction } from '../../types';
import { MerchantSelection, MerchantSelectionResult } from './MerchantSelection';
import { Divider, Typography } from '@mui/material';
import { BusinessEventSelection, BusinessEventSelectionResult } from './BusinessEventSelection';
import { CategorizationResult } from './index';
import { isEqual, orderBy } from 'lodash';
import {
  SimulatedLoanUpdate,
  SimulatedLoanFailure,
  isSimulatedLoanIrrelevantEvent,
  isSimulatedLoanUpdate,
} from '../../__generated-global__';

import BigNumber from 'bignumber.js';
import { simulateLoanFromTransaction } from '../../shared/loans';
import { LoanApproveDialog } from './LoanApproveDialog';
import { COMPANY_AFFILIATE_REQUIREMENTS, RELEVANT_AFFILIATE_TYPES } from '../../shared/classification-utils';
import { globalRulesService } from '../../services/global-rules';
import { checkHasAccrual, useIsDisabledByAccrual } from './useIsDisabledByAccrual';
import { getClassificationFromCategorizationInput } from '../BankTransactions/BankTransactionsTable';

export interface CategorizationDialogProps {
  companyId: string;
  bankTransaction: EnrichedBankTransaction | null;
  isOpen: boolean;
  onClose: () => void;
  onCategorizationChange: (result: CategorizationResult) => void;
}

export const CategorizationDialog = memo(
  ({
    companyId,
    bankTransaction,
    isOpen,
    onCategorizationChange,
    onClose,
  }: CategorizationDialogProps): ReactElement => {
    const [shouldCreateGlobalRule, setShouldCreateGlobalRule] = useState<boolean>(false);
    const [selectedBusinessMeaning, setSelectedBusinessMeaning] = useState<BusinessMeaning>();
    const [categorizationResult, setCategorizationResult] = useState<CategorizationResult>({
      merchantResult: { merchant: null, shouldApplyAlways: false },
      businessEventResult: { classifications: [], shouldApplyAlways: false },
    });

    const [simulatedLoanResult, setSimulatedLoanResult] = useState<SimulatedLoanUpdate | SimulatedLoanFailure | null>(
      null,
    );

    const { isDisabledByAccrual, setDisabledByAccrual } = useIsDisabledByAccrual(companyId, bankTransaction?.id);

    const handleCategorization = async () => {
      if (!bankTransaction?.id) return;

      if (await checkHasAccrual(companyId, bankTransaction.id)) {
        setDisabledByAccrual(true);
        return;
      }

      const { merchant } = categorizationResult.merchantResult;

      const classifications = getClassificationFromCategorizationInput(categorizationResult);

      const loanSimulationResult = await simulateLoanFromTransaction(
        companyId,
        bankTransaction?.id,
        classifications?.map((c) => ({
          businessEvent: c.businessEvent!,
          amount: c.amount,
          pairedEntityId: c.pairedEntityId,
          pairedEntityType: c.pairedEntityType,
        })),
        merchant?.id,
        merchant?.type,
      );

      //if not relevant or the update is not creation or deletion
      if (
        isSimulatedLoanIrrelevantEvent(loanSimulationResult) ||
        (isSimulatedLoanUpdate(loanSimulationResult) &&
          !loanSimulationResult.createdLoan &&
          !loanSimulationResult.deletedLoan)
      ) {
        onCategorizationChange(categorizationResult);
      } else {
        setSimulatedLoanResult(loanSimulationResult);
      }

      // save global rule
      if (shouldCreateGlobalRule && merchant && selectedBusinessMeaning) {
        const globalRule = globalRulesService.buildRule(merchant, selectedBusinessMeaning, bankTransaction);
        console.log({ globalRule });
        await globalRulesService.createGlobalRule(globalRule);
      }
    };

    const handleLoanApproveDialogClose = () => {
      const isLoanUpdate = simulatedLoanResult && isSimulatedLoanUpdate(simulatedLoanResult);
      setSimulatedLoanResult(null);
      if (isLoanUpdate && categorizationResult) {
        onCategorizationChange(categorizationResult);
      }
    };

    const handleOnClose = () => {
      onClose();
    };

    const handleMerchantSelectionChange = ({
      merchant,
      shouldApplyMerchantToSimilarTransactions,
    }: MerchantSelectionResult) => {
      const nextCategorizationResult: CategorizationResult = {
        ...categorizationResult,
        merchantResult: { merchant, shouldApplyAlways: shouldApplyMerchantToSimilarTransactions },
      };

      hasCategorizationResultChanged({ currentCategorizationResult: categorizationResult, nextCategorizationResult }) &&
        setCategorizationResult(nextCategorizationResult);
    };

    const handleShouldApplyGlobalRuleChange = (isApplyGlobally: boolean) => {
      setShouldCreateGlobalRule(isApplyGlobally);
    };

    const handleBusinessEventSelectionChange = ({
      businessMeanings,
      pairedEntityType,
      pairedEntityId,
      pairingType,
      shouldApplyAlways,
    }: BusinessEventSelectionResult) => {
      const nextCategorizationResult: CategorizationResult = {
        ...categorizationResult,
        businessEventResult: {
          classifications: businessMeanings.map(({ businessMeaning, amount }) => ({
            businessEvent: businessMeaning.id,
            amount,
            pairedEntityType,
            pairedEntityId,
            pairingType,
          })),
          shouldApplyAlways,
        },
      };

      hasCategorizationResultChanged({ currentCategorizationResult: categorizationResult, nextCategorizationResult }) &&
        setCategorizationResult(nextCategorizationResult);

      setSelectedBusinessMeaning(businessMeanings[0]?.businessMeaning || null);
    };

    useEffect(() => {
      if (bankTransaction != null) {
        setCategorizationResult(categorizationResultFromBankTransaction(bankTransaction));
      }
    }, [bankTransaction]);

    const { isSaveDisabled, reason } = determineSaveState(bankTransaction, categorizationResult, isDisabledByAccrual);

    return (
      <>
        <Dialog
          isOKDisabled={isSaveDisabled}
          isOpen={isOpen}
          okText="Save"
          onOK={handleCategorization}
          onClose={handleOnClose}
          title="Categorization"
          maxWidth={false} // make dialog full screen
        >
          {bankTransaction == null ? (
            <>Please select a transaction first</>
          ) : (
            <>
              <MerchantSelection
                companyId={companyId}
                bankTransaction={bankTransaction}
                categorizationResult={categorizationResult}
                onMerchantSelectionChange={handleMerchantSelectionChange}
                isDisabled={isDisabledByAccrual}
              />

              <Divider sx={{ my: 2 }} />

              <BusinessEventSelection
                companyId={companyId}
                bankTransaction={bankTransaction}
                categorizationResult={categorizationResult}
                onShouldApplyGlobalRuleChange={handleShouldApplyGlobalRuleChange}
                onBusinessEventSelectionChange={handleBusinessEventSelectionChange}
                isDisabled={isDisabledByAccrual}
              />

              {isSaveDisabled && reason && (
                <Typography color="red" fontSize={14}>
                  {reason}
                </Typography>
              )}
            </>
          )}
        </Dialog>
        <LoanApproveDialog
          isOpen={!!simulatedLoanResult}
          onClose={() => setSimulatedLoanResult(null)}
          onOK={handleLoanApproveDialogClose}
          effect={simulatedLoanResult}
        />
      </>
    );
  },
);

const categorizationResultFromBankTransaction = (
  bankTransaction: EnrichedBankTransaction | null,
): CategorizationResult => {
  const isMerchantClassifiedByLocalRule =
    bankTransaction?.merchant?.classifierType === BankTransactionClassifierType.LOCAL_RULE ||
    !!bankTransaction?.merchant?.classifiedBy?.includes('#vendor');
  const isBusinessEventClassifiedByLocalRule =
    bankTransaction?.businessEvent?.classifierType === BankTransactionClassifierType.LOCAL_RULE ||
    !!bankTransaction?.businessEvent?.classifiedBy?.includes('#vendorToBusinessMeaning');

  return {
    merchantResult: {
      merchant: bankTransaction?.merchant || null,
      shouldApplyAlways: isMerchantClassifiedByLocalRule,
    },
    businessEventResult: {
      classifications:
        bankTransaction?.businessEvent?.classifications?.map(({ businessMeaning, classificationTagText, ...rest }) => ({
          ...rest,
        })) || [],
      shouldApplyAlways: isBusinessEventClassifiedByLocalRule,
    },
  };
};

const hasCategorizationResultChanged = ({
  currentCategorizationResult,
  nextCategorizationResult,
}: {
  currentCategorizationResult: CategorizationResult;
  nextCategorizationResult: CategorizationResult;
}): boolean => {
  const { merchantResult: currentMerchantResult, businessEventResult: currentBusinessEventResult } =
    currentCategorizationResult;
  const { merchantResult: nextMerchantResult, businessEventResult: nextBusinessEventResult } = nextCategorizationResult;

  const hasMerchantChanged =
    currentMerchantResult.merchant?.id !== nextMerchantResult.merchant?.id ||
    currentMerchantResult.shouldApplyAlways !== nextMerchantResult.shouldApplyAlways;

  const hasBusinessEventChanged =
    !isEqual(
      orderBy(currentBusinessEventResult.classifications, ['businessEvent', 'amount']),
      orderBy(nextBusinessEventResult.classifications, ['businessEvent', 'amount']),
    ) || currentBusinessEventResult.shouldApplyAlways !== nextBusinessEventResult.shouldApplyAlways;

  return hasMerchantChanged || hasBusinessEventChanged;
};

const determineSaveState = (
  bankTransaction: EnrichedBankTransaction | null,
  categorizationResult: CategorizationResult,
  isDisabledByAccrual: boolean,
): { isSaveDisabled: boolean; reason?: JSX.Element } => {
  if (
    !hasCategorizationResultChanged({
      currentCategorizationResult: categorizationResultFromBankTransaction(bankTransaction),
      nextCategorizationResult: categorizationResult,
    })
  ) {
    return { isSaveDisabled: true };
  }

  const { businessEventResult } = categorizationResult;

  const containsCompanyAffiliateSelection = businessEventResult.classifications.some(
    ({ businessEvent }) => businessEvent && COMPANY_AFFILIATE_REQUIREMENTS[businessEvent],
  );

  if (
    categorizationResult.merchantResult.merchant?.type === 'COMPANY_AFFILIATE' &&
    !categorizationResult.merchantResult.merchant.merchantSubtype
  ) {
    throw new Error(
      `Merchant subtype is required for COMPANY_AFFILIATE merchants. Merchant id: ${categorizationResult.merchantResult.merchant.id}`,
    );
  }

  if (
    containsCompanyAffiliateSelection &&
    !RELEVANT_AFFILIATE_TYPES.includes(categorizationResult.merchantResult.merchant?.merchantSubtype ?? '')
  ) {
    return {
      isSaveDisabled: true,
      reason: (
        <>
          This business event requires a holder/owner/relatedParty to be selected.
          <br />
          {`Currently the transaction is classified with ${
            categorizationResult.merchantResult.merchant?.merchantSubtype ||
            categorizationResult.merchantResult.merchant?.type
          }`}
        </>
      ),
    };
  }

  const totalClassificationAmount = businessEventResult.classifications
    .reduce((acc, { amount }) => acc.plus(amount), BigNumber(0))
    .abs()
    .toNumber();

  if (
    businessEventResult.classifications.length > 0 &&
    totalClassificationAmount !== Math.abs(bankTransaction?.amount || 0)
  ) {
    return { isSaveDisabled: true };
  }

  if (isDisabledByAccrual) {
    return {
      isSaveDisabled: true,
      reason: <>This action is blocked because the transaction has been spread</>,
    };
  }

  return { isSaveDisabled: false };
};
