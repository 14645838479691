import { type HTMLAttributes, memo, type ReactElement, useCallback, useMemo, useState } from 'react';
import { Autocomplete, Avatar, Box, FormControl, LinearProgress, Stack, TextField } from '@mui/material';
import { isObject, keyBy, startCase, uniqBy } from 'lodash';

import { useFilter, useGetOptionLabel, useHandleSelectionChange } from './hooks';
import type { MerchantSelectorProps } from './types';
import { ConfigService } from '../../services/config';
import { useQuery } from '@tanstack/react-query';
import { createClient as createMerchantsClient } from '../../__genql__/merchants';
import { isCompanyAffiliate, isVendor, Merchant } from '../../__generated-global__';
import { TokenService } from '../../services/auth/TokenService';
import { gqlClient } from '../../shared/gqlClient';
import { MerchantTypenameToType } from '../../shared';

export const merchantsClient = createMerchantsClient({
  url: `${ConfigService.getInstance().getOrFail('MERCHANTS_GQL_API')}`,
  headers: async () => {
    const token = await TokenService.getInstance().getToken();
    return { Authorization: `Bearer ${token}` };
  },
});

async function searchMerchants({ queryKey: [_, companyId, term] }: any) {
  return await gqlClient.query({
    __name: 'searchMerchants',
    searchMerchants: {
      __args: {
        input: {
          companyId,
          searchTerm: term,
        },
      },
      on_CompanyAffiliate: {
        id: true,
        name: true,
        type: true,
        companyId: true,
        __typename: true,
      },
      on_Customer: {
        id: true,
        name: true,
        __typename: true,
      },
      on_Vendor: {
        id: true,
        name: true,
        logoUrl: true,
        subType: true,
        integrationType: true,
        companyId: true,
        __typename: true,
      },
    },
  });
}

export const MerchantSelector = memo((props: MerchantSelectorProps): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const { data: merchants = [] } = useQuery(['merchants', props.companyId, searchTerm], searchMerchants, {
    // only fetch search terms longer than 2 characters
    enabled: searchTerm.length > 1,
    refetchOnWindowFocus: false,
    // refresh cache after 10 seconds (watch the network tab!)
    staleTime: 10 * 1000,
    select: ({ searchMerchants }) => searchMerchants,
  });

  const merchantsDictionary = useMemo(() => keyBy(merchants, 'id'), [merchants]);
  const handleSelectionChange = useHandleSelectionChange({ ...props, setIsAdding });
  const filterOptions = useFilter(props.canAdd);
  const getOptionLabel = useGetOptionLabel();

  const renderInput = useCallback((params: any) => <TextField {...params} label="Merchant" variant="filled" />, []);
  const updateSearchTerm = useCallback((_event: any, searchTerm: string) => setSearchTerm(searchTerm), []);

  const selectedMerchant = merchantsDictionary[props.value?.id || ''];

  const renderOption = useCallback((props: HTMLAttributes<HTMLLIElement>, option: any) => {
    return (
      <li {...props} key={option.id}>
        {option ? optionAvatar(option) : undefined}
        <span>{(option.name || option.label).trim()}</span>
        <span style={{ flexGrow: 1, fontStyle: 'italic', fontSize: '70%', textAlign: 'right' }}>
          {startCase((option.type || 'VENDOR').replace(/_/g, ' ').toLowerCase())}
          {option.__typename === 'Vendor' ? ` (${option.companyId ? 'local' : 'global'})` : null}
        </span>
      </li>
    );
  }, []);

  return (
    <Stack sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <Autocomplete
          clearOnBlur
          disabled={!!props.isDisabled || !!props.isLoading || isAdding}
          filterOptions={filterOptions}
          freeSolo={props.canAdd}
          getOptionLabel={getOptionLabel}
          handleHomeEndKeys
          id="merchant-selector--autocomplete"
          onInputChange={updateSearchTerm}
          isOptionEqualToValue={(option: unknown, value: unknown) =>
            (option as { id: string }).id === (value as { id: string })?.id
          }
          onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => {
            handleSelectionChange(
              event,
              isObject(value)
                ? {
                    ...value,
                    type: MerchantTypenameToType[(value as any).__typename],
                    merchantSubtype: (value as any).type,
                  }
                : value,
            );
          }}
          options={uniqBy(
            merchants.filter((merchant) => (isCompanyAffiliate(merchant) && merchant.type === 'lender' ? false : true)), // filter lenders for now
            ({ name, companyId, __typename }) => `${name}-${companyId}`,
          )}
          renderInput={renderInput}
          renderOption={renderOption as any}
          selectOnFocus
          defaultValue={props.value}
          value={selectedMerchant}
        />
      </FormControl>
      <Box sx={{ height: '5px' }}>{props.isLoading || isAdding ? <LinearProgress variant="query" /> : undefined}</Box>
    </Stack>
  );
});

const optionAvatar = (option: Merchant) => {
  const label = (option as any).name || (option as any).label;
  let letter = label.trim().charAt(0).toUpperCase();
  letter = letter === '[' ? '?' : letter;

  return (
    <Avatar sx={{ width: 24, height: 24, marginRight: '10px' }} src={'logoUrl' in option ? option.logoUrl : undefined}>
      {letter}
    </Avatar>
  );
};
